.regdropdown .my-account-link {
    background: #e0e1e2;
    color: #333;
    padding-right: 33px;
    position: relative;
  }
  .regdropdown .my-account-link:hover {
    background:  #cbcdce;
  }
  .regdropdown .my-account-link::after {
    content: "";
    color: #000;
    cursor: pointer;
    pointer-events: none;
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    top: 11px;
    right: 13px;
  }
.page-wrapper.route-landing .header-left, .page-wrapper.route-landing .header-search, .page-wrapper.route-landing .header-right .tooltip {
    display: none;
  }

.page-wrapper.route-landing .field.note {
    font-size: 11px;
    text-align: center;
  }

.page-wrapper.route-landing .block-title {
    text-align: center;
  }

.homepage-search-buttons.landing-buttons > a {
    display: block;
  }
  .homepage-search-buttons.landing-buttons {
    display: unset;
  }

.home-search-button-primary.large_region {
    border-radius: 50px;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    min-width: 300px;
  }

#content\ cms-content {
    max-width: 800px;
    display: block;
    margin: auto;
    padding-top: 38px;
}

.product-tabs .product-links li a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.catalog-product-view .product-contenst {
    min-height: 400px;
}

.concept_group_item>label.custom-checkbox {
    display: flex;
}

.product.actions.product-item-actions>div>div>div:after {
    content: "";
    color: #fff;
    cursor: pointer;
    pointer-events: none;
    font-family: "Font Awesome 5 Pro";
    font-weight: bold;
    position: absolute;
    top: 7px;
    right: 11px;
    font-size: 18px;
}

.product.actions.product-item-actions>div>div>div {
    background: green !important;
    width: 95% !important;
    position: unset;
    left: 2.5%;
    z-index: 2000 !important;
}

span.css-19bon8n {
    display: inline-block;
    width: calc(25% - 10px);
    min-height: 350px;
    margin-right: 10px;
    margin-bottom: 10px;
}

#shopping-cart-table>tbody:nth-child(2n) {
    background: #f7f7f7 !important;
}

.col.prices,
.col.qty,
.col.subtotal,
.col.actions {
    width: 10% !important;
}

.col.availability {
    width: 20% !important;
}

#shopping-cart-table>thead>tr>th:first-of-type,
#shopping-cart-table>tbody>tr>td:first-of-type {
    text-align: left;
}

#shopping-cart-table>thead>tr>th:last-of-type,
#shopping-cart-table>tbody>tr>td:last-of-type {
    text-align: right;
}

#shopping-cart-table>thead>tr>th,
#shopping-cart-table>tbody>tr>td {
    text-align: center;
}

.col.pack {
    width: 40%;
}

.product-price-list-container {
    padding-top: 0 !important;
}

#amasty-shopby-product-list .product-item .product-price-list-container {
    padding-top: 0px;
}

.item.product.product-item td>.action.tocart.primary {
    height: 25px;
    width: 25px;
    padding: 0;
}

.item.product.product-item .action.tocart.added-to-cart {
    height: 25px;
    width: 25px;
    padding: 0;
}

.item.product.product-item .action.tocart.primary i {
    font-size: 12px;
}

.checkout-cart-index .parent-item.name {
    font-size: 18px;
}

.concept_pagination button {
    position: relative;
    background: #e62645;
    min-height: 28px;
}

.concept_pagination button:first-of-type {
    margin-right: 2px;
}

.concept_pagination button:first-of-type:after {
    content: "";
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 6px;
    right: 11px;
    font-weight: bold;
    cursor: pointer;
    pointer-events: none;
}

.concept_pagination button:last-of-type:after {
    content: "";
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 6px;
    right: 9px;
    font-weight: bold;
    cursor: pointer;
    pointer-events: none;
}

.concept_pagination span {
    margin-top: 5px;
    font-size: 12px;
    margin-right: 6px;
}

.concept_pagination {
    display: flex;
    justify-content: end;
}

.page-wrapper.route-sketcher #sketch {
    width: 60% !important;
    min-width: unset !important;
    margin: 0 auto;
    margin-bottom: 0px;
    display: block;
    margin-bottom: 10px;
    min-height: 413px !important;
}

.route-advanced .filter.block {
    margin-bottom: 40px;
    //width: 406px;
}

.page-wrapper.route-advanced>div>div {
    //width: 550px;
}

.sketcher-buttons select option {
    background: #fff;
    color: #000;
    font-weight: normal;
    cursor: pointer;
}

.sketcher-buttons::after {
    content: "";
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 6px;
    right: 8px;
    font-weight: bold;
    cursor: pointer;
    pointer-events: none;
}

.sketcher-buttons {
    padding-top: 0px;
    margin-top: -6px;
    position: relative;

}

.sketcher-buttons select {
    border-radius: 0;
    cursor: pointer;
    font-weight: bold;
    background: #000;
    color: #fff;
}

._keyfocus :focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: none;
}

.pages .action.next:hover::before,
.pages .action.previous:hover::before {
    color: #e62645;
    font-weight: 900;
    font-size: 24px;
}

.action.previous,
.action.next {
    min-width: 30px;
}

#amasty-shopby-product-list .pages ul li.item a.page:hover {
    background: #cecece;
}

#amasty-shopby-product-list .pages ul li {
    cursor: pointer;
}

.header-right>.minicart-wrapper:last-of-type {
    margin-right: 0;
}

.header-right>.minicart-wrapper:last-of-type {
    margin-right: 0;
}

.minicart-wrapper .action.showcart {
    position: relative;
}

.header-right .minicart-wrapper {
    border-radius: 5px;
    padding: 4px;
    min-width: 43px;
    text-align: center;
    cursor: pointer;
}

.header-right .minicart-wrapper:hover {
    background: #e0e1e2;
}

span.counter-label {
    position: absolute;
    right: 0;
    bottom: -3px;
    background: #e62645;
    padding: 5px;
    border-radius: 15px;
    line-height: 5px;
    font-size: 10px;
    font-weight: 900;
    color: #fff;
    width: 15px;
    height: 15px;
}

.minicart-wrapper .action.showquote::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    content: "";
    vertical-align: top;
    speak: none;
    text-align: center;
    margin: 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    display: inline-block;
    overflow: hidden;
    min-width: 43px;
}

.product-info-main .product-options-bottom .box-tocart button.toquote::before,
.product-info-main .product-options-bottom a.toquote::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: "";
    vertical-align: top;
    speak: none;
    text-align: center;
    margin: 0 5px 0 0;
    font-family: "Font Awesome 5 Pro";
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
}

.product.details.product-item-details {
    position: relative;
}

#amasty-shopby-product-list .product-item .product-price-list-container {
    width: calc(100% - 4px);
    z-index: 99;
    text-align: left;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #e0e1e2;
    border-radius: 0 5px 5px 0;
    margin: 0 auto;
    padding: 10px;
    position: absolute;
    top: -264px;
    left: 2px;
    overflow: hidden;
    border: none;
    min-height: 250px;
    right: auto;
}

.product-image-container {
    cursor: pointer;
}

.product-price-list-container {
    cursor: pointer;
}

#maincontent {
    justify-content: normal;
}

.filter-options-content {
    margin-bottom: -20px;
}

.slidecontainer {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    /* Width of the outside container */
}

.d-flex {
    display: flex;
}

.range {
    display: flex;
    width: 100%;
    background-color: #b6b6b6;
    height: 4px;
    margin-bottom: 10px;
    user-select: none;
    margin-top: 15px;
    position: relative;
}

.thumb {
    background: #e62645;
    border-radius: 10px;
    width: 16px;
    height: 16px;
    position: absolute;
    user-select: none;
    top: -6px;
    cursor: pointer;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 8px;
    /* Specified height */
    background: rgb(182, 182, 182);
    /* Grey background */
    outline: none;
    /* Remove outline */

    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    margin-left: -50px;
    margin-right: -50px;
}

/* Mouse-over effects */
.slidecontainer:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

.slider:focus {
    box-shadow: unset;

}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 18px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: rgb(249, 139, 37);

    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 10px;
    opacity: 2 !important;
}

.slider::-moz-range-thumb {
    width: 18px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: rgb(249, 139, 37);
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 10px;
    opacity: 2 !important;
}

.slider::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;


}

.slider::-webkit-slider-runnable-track {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    z-index: 99999;
}

.slider:focus {
    box-shadow: unset !important;
}

.slider::thumb {
    z-index: - 9999;
}


.concepts-input {
    margin-bottom: 8px;
}


.warning {
    background-color: darkorange;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 10px;
    color: white;
}

.concept {
    text-align: center;
    font-size: 12px;
    width: 18px !important;
    height: 18px !important;
    color: white;
}

.concept.include {
    background-color: green !important;
    content: "V";
}

.concept.exclude {
    background-color: red !important;
    content: "X"
}


/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
}



.logo2 {
    display: none;
}


body.fluo {
    .logo2 {
        display: inline;
    }
    .logo1 {
        display: none;
    }
}


.loading {
    width: 100%;
    text-align: center;
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  
.loading div {
    width: 100%;
    margin-top: -10px;
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  .loading div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  @keyframes loading {
    0% {
      top: 18px;
      left: 18px;
      width: 0;
      height: 0;
      opacity: 1;
    }
  
    100% {
      top: 0px;
      left: 0px;
      width: 36px;
      height: 36px;
      opacity: 0;
    }
  }
  
  
  
  
  .loading.gray div {
    border: 4px solid #888
  }
  
  