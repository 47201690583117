.regdropdown .my-account-link {
  color: #333;
  background: #e0e1e2;
  padding-right: 33px;
  position: relative;
}

.regdropdown .my-account-link:hover {
  background: #cbcdce;
}

.regdropdown .my-account-link:after {
  content: "";
  color: #000;
  cursor: pointer;
  pointer-events: none;
  font-family: "Font Awesome 5 Pro";
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  top: 11px;
  right: 13px;
}

.page-wrapper.route-landing .header-left, .page-wrapper.route-landing .header-search, .page-wrapper.route-landing .header-right .tooltip {
  display: none;
}

.page-wrapper.route-landing .field.note {
  text-align: center;
  font-size: 11px;
}

.page-wrapper.route-landing .block-title {
  text-align: center;
}

.homepage-search-buttons.landing-buttons > a {
  display: block;
}

.homepage-search-buttons.landing-buttons {
  display: unset;
}

.home-search-button-primary.large_region {
  height: 50px;
  min-width: 300px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
}

#content\ cms-content {
  max-width: 800px;
  margin: auto;
  padding-top: 38px;
  display: block;
}

.product-tabs .product-links li a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.catalog-product-view .product-contenst {
  min-height: 400px;
}

.concept_group_item > label.custom-checkbox {
  display: flex;
}

.product.actions.product-item-actions > div > div > div:after {
  content: "";
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 7px;
  right: 11px;
}

.product.actions.product-item-actions > div > div > div {
  position: unset;
  left: 2.5%;
  width: 95% !important;
  z-index: 2000 !important;
  background: green !important;
}

span.css-19bon8n {
  width: calc(25% - 10px);
  min-height: 350px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
}

#shopping-cart-table > tbody:nth-child(2n) {
  background: #f7f7f7 !important;
}

.col.prices, .col.qty, .col.subtotal, .col.actions {
  width: 10% !important;
}

.col.availability {
  width: 20% !important;
}

#shopping-cart-table > thead > tr > th:first-of-type, #shopping-cart-table > tbody > tr > td:first-of-type {
  text-align: left;
}

#shopping-cart-table > thead > tr > th:last-of-type, #shopping-cart-table > tbody > tr > td:last-of-type {
  text-align: right;
}

#shopping-cart-table > thead > tr > th, #shopping-cart-table > tbody > tr > td {
  text-align: center;
}

.col.pack {
  width: 40%;
}

.product-price-list-container {
  padding-top: 0 !important;
}

#amasty-shopby-product-list .product-item .product-price-list-container {
  padding-top: 0;
}

.item.product.product-item td > .action.tocart.primary, .item.product.product-item .action.tocart.added-to-cart {
  height: 25px;
  width: 25px;
  padding: 0;
}

.item.product.product-item .action.tocart.primary i {
  font-size: 12px;
}

.checkout-cart-index .parent-item.name {
  font-size: 18px;
}

.concept_pagination button {
  min-height: 28px;
  background: #e62645;
  position: relative;
}

.concept_pagination button:first-of-type {
  margin-right: 2px;
}

.concept_pagination button:first-of-type:after {
  content: "";
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 11px;
}

.concept_pagination button:last-of-type:after {
  content: "";
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 9px;
}

.concept_pagination span {
  margin-top: 5px;
  margin-right: 6px;
  font-size: 12px;
}

.concept_pagination {
  justify-content: end;
  display: flex;
}

.page-wrapper.route-sketcher #sketch {
  margin: 0 auto 10px;
  display: block;
  width: 60% !important;
  min-width: unset !important;
  min-height: 413px !important;
}

.route-advanced .filter.block {
  margin-bottom: 40px;
}

.sketcher-buttons select option {
  color: #000;
  cursor: pointer;
  background: #fff;
  font-weight: normal;
}

.sketcher-buttons:after {
  content: "";
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 8px;
}

.sketcher-buttons {
  margin-top: -6px;
  padding-top: 0;
  position: relative;
}

.sketcher-buttons select {
  cursor: pointer;
  color: #fff;
  background: #000;
  border-radius: 0;
  font-weight: bold;
}

._keyfocus :focus, input:not([disabled]):focus, textarea:not([disabled]):focus, select:not([disabled]):focus {
  box-shadow: none;
}

.pages .action.next:hover:before, .pages .action.previous:hover:before {
  color: #e62645;
  font-size: 24px;
  font-weight: 900;
}

.action.previous, .action.next {
  min-width: 30px;
}

#amasty-shopby-product-list .pages ul li.item a.page:hover {
  background: #cecece;
}

#amasty-shopby-product-list .pages ul li {
  cursor: pointer;
}

.header-right > .minicart-wrapper:last-of-type {
  margin-right: 0;
}

.minicart-wrapper .action.showcart {
  position: relative;
}

.header-right .minicart-wrapper {
  min-width: 43px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
}

.header-right .minicart-wrapper:hover {
  background: #e0e1e2;
}

span.counter-label {
  color: #fff;
  width: 15px;
  height: 15px;
  background: #e62645;
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
  font-weight: 900;
  line-height: 5px;
  position: absolute;
  bottom: -3px;
  right: 0;
}

.minicart-wrapper .action.showquote:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  content: "";
  vertical-align: top;
  speak: none;
  text-align: center;
  min-width: 43px;
  margin: 0;
  font-family: "Font Awesome 5 Pro";
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  display: inline-block;
  overflow: hidden;
}

.product-info-main .product-options-bottom .box-tocart button.toquote:before, .product-info-main .product-options-bottom a.toquote:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  content: "";
  vertical-align: top;
  speak: none;
  text-align: center;
  margin: 0 5px 0 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.product.details.product-item-details {
  position: relative;
}

#amasty-shopby-product-list .product-item .product-price-list-container {
  width: calc(100% - 4px);
  z-index: 99;
  text-align: left;
  min-height: 250px;
  background: #fffc;
  border: none;
  border-radius: 0 5px 5px 0;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  top: -264px;
  left: 2px;
  right: auto;
  overflow: hidden;
}

.product-image-container, .product-price-list-container {
  cursor: pointer;
}

#maincontent {
  justify-content: normal;
}

.filter-options-content {
  margin-bottom: -20px;
}

.slidecontainer {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
}

.d-flex {
  display: flex;
}

.range {
  width: 100%;
  height: 4px;
  -webkit-user-select: none;
  user-select: none;
  background-color: #b6b6b6;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.thumb {
  width: 16px;
  height: 16px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background: #e62645;
  border-radius: 10px;
  position: absolute;
  top: -6px;
}

.slider {
  appearance: none;
  width: 100%;
  height: 8px;
  -webkit-transition: all .2s;
  background: #b6b6b6;
  outline: none;
  margin-left: -50px;
  margin-right: -50px;
  transition: opacity .2s;
}

.slidecontainer:hover {
  opacity: 1;
}

.slider:focus {
  box-shadow: unset;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #f98b25;
  border-radius: 10px;
  opacity: 2 !important;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #f98b25;
  border-radius: 10px;
  opacity: 2 !important;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  box-shadow: none;
}

.slider::-webkit-slider-runnable-track {
  appearance: none;
  box-shadow: none;
  z-index: 99999;
}

.slider:focus {
  box-shadow: unset !important;
}

.slider::thumb {
  z-index: -9999;
}

.concepts-input {
  margin-bottom: 8px;
}

.warning {
  color: #fff;
  background-color: #ff8c00;
  border-radius: 10px;
  margin: 5px;
  padding: 5px 10px;
}

.concept {
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 18px !important;
  height: 18px !important;
}

.concept.include {
  content: "V";
  background-color: green !important;
}

.concept.exclude {
  content: "X";
  background-color: red !important;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  width: 120px;
  margin-left: -60px;
  top: 100%;
  left: 50%;
}

.logo2 {
  display: none;
}

body.fluo .logo2 {
  display: inline;
}

body.fluo .logo1 {
  display: none;
}

.loading {
  width: 100%;
  text-align: center;
  width: 24px;
  height: 24px;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

.loading div {
  width: 100%;
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  margin-top: -10px;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite loading;
  position: absolute;
}

.loading div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes loading {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    top: 18px;
    left: 18px;
  }

  100% {
    width: 36px;
    height: 36px;
    opacity: 0;
    top: 0;
    left: 0;
  }
}

.loading.gray div {
  border: 4px solid #888;
}

/*# sourceMappingURL=index.8412b6b4.css.map */
